<template lang='pug'>
  .p-before-login
    LoadingIcon
</template>

<script>
import LoadingIcon from 'src/components/atoms/LoadingIcon'

export default {
  name: 'BeforeLogin',
  components: { LoadingIcon }
}
</script>
<style lang="scss" scoped>
.p-before-login {
  width: 100vw;
  height: 100vh;
  @include flex(center, center);
}
</style>
