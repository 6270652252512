import './application'
import App from 'src/before_login/pages/application.vue'
import Vue from 'vue'

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#before-login',
    render: h => h(App)
  })
})
